<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div
      class="modal fade"
      id="modal-form-reporte-tepNominacion"
      style="background-color: #00000082"
    >
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">Generar Reporte Nominaciones</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal1"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
              <li class="nav-item">
                <a
                  class="nav-link active"
                  id="tab-Filtros"
                  data-toggle="tab"
                  href="#Filtros"
                  >Filtros
                </a>
              </li>
            </ul>
            <div class="tab-content" id="myTabContent">
              <div class="tab-pane fade active show" id="Filtros">
                <div class="card-body">
                  <div class="form-group row">
                    <label for="id_nomina" class="col-md-5">Sitio</label>
                    <v-select
                      :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                      v-model="form.sitio"
                      placeholder="Sitio"
                      label="nombre"
                      :options="listasForms.sitios"
                      class="form-control form-control-sm p-0"
                    ></v-select>
                    <label for="id_nomina" class="col-md-5"
                      >Fecha inicial</label
                    >
                    <input
                      type="date"
                      class="form-control form-control-sm"
                      v-model="form.fecha_ini"
                    />
                    <label for="id_nomina" class="col-md-5">Fecha final</label>
                    <input
                      type="date"
                      class="form-control form-control-sm"
                      v-model="form.fecha_fin"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="modal-footer justify-content-between">
            <div>
              <button
                type="button"
                class="btn btn-sm bg-navy"
                @click="generarListadoExcel()"
              >
                Generar Listado
                <i class="fas fa-file-download"> </i>
              </button>
            </div>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Loading from "../../../../components/Loading";
import vSelect from "vue-select";
export default {
  name: "TepReporteNominacionExport",
  components: {
    Loading,
    vSelect,
  },
  data() {
    return {
      cargando: false,
      form: {
        sitio: null,
        fecha_ini: null,
        fecha_fin: null,
      },
      listasForms: {
        sitios: [],
      },
    };
  },

  methods: {
    buscarSitios() {
      let me = this;
      var url = "api/admin/sitios/lista";
      axios
        .get(url, {
          params: {
            estado: 1,
          },
        })
        .then(function(response) {
          let respuesta = response.data;
          me.listasForms.sitios = respuesta;
        })
        .catch(function(error) {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    generarListadoExcel() {
      // this.cargando = true;
      axios({
        method: "POST",
        url: "/api/tep/nominacion/reporte",
        data: this.form,
      })
        .then((response) => {
          let data = response.data;
          this.cargando = false;
          location.href = data.url;
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.cargando = false;
        });
    },
  },

  async mounted() {
    this.cargando = true;
    this.buscarSitios();
    this.cargando = false;
  },
};
</script>
